@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('https://cdn.is.symboolic.com/bc/v1/sophia/fonts/GTEestiProDisplay-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('https://cdn.is.symboolic.com/bc/v1/sophia/fonts/GTEestiProDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('https://cdn.is.symboolic.com/bc/v1/sophia/fonts/GTEestiProDisplay-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('https://cdn.is.symboolic.com/bc/v1/sophia/fonts/GTEestiProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'GT Eesti Display';
    src: url('https://cdn.is.symboolic.com/bc/v1/sophia/fonts/GTEestiDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'GT Eesti Display';
    src: url('https://cdn.is.symboolic.com/bc/v1/sophia/fonts/GTEestiDisplay-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.absolute-center-v {
    @apply top-1/2 -translate-y-1/2;
}

.absolute-center-h {
    @apply right-1/2 translate-x-1/2;
}

body {
    margin: 0;
    /* font-family: 'GT Eesti Pro Display', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: hsl(var(--twc-blue)) hsl(var(--twc-blue-dark));
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background: hsl(var(--twc-blue-dark));
}

*::-webkit-scrollbar-thumb {
    background-color: hsl(var(--twc-blue));
    border-radius: 14px;
    border: 3px solid hsl(var(--twc-blue-dark));
}

*::-webkit-scrollbar-corner {
    background: hsl(var(--twc-blue-dark));
    border: solid hsl(var(--twc-blue));
    border-width: 1px 0px 0px 1px;
}

a {
    color: hsl(var(--twc-azure-pastel));
}

.h-smallest-screen {
    height: 100svh !important;
}